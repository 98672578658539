@charset "UTF-8";
@font-face {
  font-family: "Geometria";
  src: url("../font/Geometria-Regular.eot?#iefix") format("embedded-opentype"), url("../font/Geometria-Regular.woff2") format("woff2"), url("../font/Geometria-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: "Geometria";
  src: url("../font/Geometria-Light.eot?#iefix") format("embedded-opentype"), url("../font/Geometria-Light.woff2") format("woff2"), url("../font/Geometria-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: "Geometria";
  src: url("../font/Geometria-Medium.eot?#iefix") format("embedded-opentype"), url("../font/Geometria-Medium.woff2") format("woff2"), url("../font/Geometria-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: "Geometria";
  src: url("../font/Geometria-Bold.eot?#iefix") format("embedded-opentype"), url("../font/Geometria-Bold.woff2") format("woff2"), url("../font/Geometria-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: "Geometria";
  src: url("../font/Geometria-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../font/Geometria-ExtraBold.woff2") format("woff2"), url("../font/Geometria-ExtraBold.woff") format("woff");
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
}

a, .link {
  outline: none;
  color: inherit;
  text-decoration: underline;
  background-color: transparent;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

a:hover, .link:hover {
  text-decoration: underline;
  color: #fff200;
}

a:active, .link:active {
  text-decoration: underline;
  color: #fff200;
}

.link--ib {
  display: inline-block;
  line-height: 1.1;
}

.link--bordered {
  text-decoration: none !important;
  border-bottom: 1px solid #fff200;
}

.link--dotted {
  text-decoration: none !important;
  border-bottom: 1px dotted;
  border-bottom-color: inherit;
}

sub, sup {
  font-size: 65%;
}

img {
  max-width: 100%;
}

img.fullwidth {
  display: block;
  width: 100%;
  height: auto;
}

p img.b-lazy {
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

p img.b-loaded {
  opacity: 1;
}

strong {
  font-weight: 700;
}

h1, .h1 {
  position: relative;
  font-family: Geometria, Arial, sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 1.33333;
  margin: 130px 0 0;
}

h1:first-child, .h1:first-child {
  margin-top: 0;
}

h2, .h2 {
  font-family: Geometria, Arial, sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.23;
  margin: 80px 0 0;
}

@media (max-width: 767px) {
  h2, .h2 {
    font-size: 32px;
  }
}

@media (max-width: 374px) {
  h2, .h2 {
    font-size: 30px;
  }
}

h2:first-child, .h2:first-child {
  margin-top: 0;
}

hr + h2, hr + .h2 {
  margin-top: 65px;
}

h3, .h3 {
  font-family: Geometria, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.45455;
  margin: 30px 0 0;
}

h3:first-child, .h3:first-child {
  margin-top: 0;
}

h4, .h4 {
  position: relative;
  font-family: Geometria, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.55556;
  text-transform: uppercase;
  margin: 55px 0 0;
}

h4:first-child, .h4:first-child {
  margin-top: 0;
}

p, .p {
  margin: 30px 0 0;
  -webkit-text-size-adjust: 100%;
}

p:first-child, .p:first-child {
  margin-top: 0;
}

ol, ul {
  margin: 24px 0 48px;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
}

ol:first-child, ul:first-child {
  margin-top: 0;
}

ol:last-child, ul:last-child {
  margin-bottom: 0;
}

ol li, ul li {
  margin: 16px 0 0;
  padding: 0 0 0 30px;
}

ol li:first-child, ul li:first-child {
  margin-top: 0;
}

li ul, li ol {
  margin-top: 7px;
}

li li {
  margin-top: 7px;
}

ul {
  list-style: none;
}

ul li {
  position: relative;
  padding-left: 14px;
  text-indent: -14px;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -o-column-break-inside: avoid;
  -ms-column-break-inside: avoid;
  column-break-inside: avoid;
  page-break-inside: avoid;
       break-inside: avoid-column;
}

ul li * {
  text-indent: 0;
}

ul li:before {
  position: relative;
  top: -3px;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3px 6px 3px;
  border-color: transparent transparent #fff200 transparent;
  margin-right: 8px;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0;
  content: "";
}

ol {
  counter-reset: ol_counter;
}

ol li {
  text-indent: -30px;
  list-style: none;
  padding: 0 0 0 45px;
}

ol li:before {
  display: inline-block;
  text-indent: 0;
  height: 20px;
  width: 20px;
  line-height: 22px;
  margin-right: 10px;
  text-align: center;
  background-color: #c2c2c2;
  border-radius: 50%;
  color: #fff;
  counter-increment: ol_counter;
  content: counter(ol_counter);
}

nav ul, .nav ul {
  margin: 0;
  padding: 0;
}

nav li, .nav li {
  margin: 0;
  padding: 0;
  text-indent: 0;
}

nav li:before, .nav li:before {
  display: none;
}

table th, table td {
  padding: 3px 10px;
}

.hr, hr {
  margin: 65px 0 0;
  padding: 0;
  border: none;
  border-top: 1px solid #000;
}

@media (max-width: 479px) {
  .hr, hr {
    margin-top: 40px;
  }
  .hr + *, hr + * {
    margin-top: 40px;
  }
}

.hr:first-child, hr:first-child {
  margin-top: 0;
}

/*

Icons

*/
.icon {
  position: relative;
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
  letter-spacing: 0;
  line-height: 1;
}

svg.icon {
  pointer-events: none;
}

.icon--marker {
  width: 0.70588em;
}

/*

Column Layout

*/
.cols {
  margin-top: 30px;
  display: table;
  width: 100%;
  table-layout: fixed;
}

.cols:first-child {
  margin-top: 0;
}

.cols--auto {
  table-layout: auto;
}

.cols--fullheight {
  height: 100%;
}

.cols__inner {
  display: table-row;
}

.col {
  display: table-cell;
  vertical-align: top;
  padding: 0 15px;
}

.cols-wrapper {
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
}

.cols-wrapper:first-child {
  margin-top: 0;
}

.cols--reverse {
  direction: rtl;
}

.cols--reverse > .col {
  direction: ltr;
}

.col--fit {
  width: 1px;
}

.col--1-4 {
  width: 25%;
}

.col--3-4 {
  width: 75%;
}

.col--1-3 {
  width: 33.33%;
}

.col--2-3 {
  width: 66.66%;
}

.col--1-2 {
  width: 50%;
}

.cols--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.cols--flex > .col {
  display: block;
}

/*

Textfields

*/
textarea,
input,
button {
  -webkit-font-smoothing: antialiased;
  outline: none;
  line-height: 1.25;
  font-family: Geometria, Arial, sans-serif;
}

.textfield {
  position: relative;
  z-index: 0;
  display: inline-block;
  font-family: Geometria, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.285714285714286;
  background: transparent;
  color: #000;
  height: 60px;
  padding: 20px 20px;
  width: 100%;
  border: none;
  -webkit-box-shadow: inset 0 0 0 1px #f0f0f0;
          box-shadow: inset 0 0 0 1px #f0f0f0;
  border-radius: 5px;
  -webkit-appearance: none !important;
  outline: none;
  resize: none;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  -webkit-transition: color 0.2s, background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
  -o-transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.textfield:focus {
  outline: none;
}

.textfield:invalid {
  background: transparent;
}

.textfield:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1px #f0f0f0, inset 0 0 0 300px #fff;
          box-shadow: inset 0 0 0 1px #f0f0f0, inset 0 0 0 300px #fff;
}

.textfield.error {
  -webkit-box-shadow: inset 0 0 0 1px #f00, inset 0 0 0 300px #fff;
          box-shadow: inset 0 0 0 1px #f00, inset 0 0 0 300px #fff;
}

.textfield::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.textfield::-webkit-input-placeholder {
  color: #888;
  opacity: 1;
}

.textfield::-moz-placeholder {
  color: #888;
  opacity: 1;
}

.textfield:-moz-placeholder {
  color: #888;
  opacity: 1;
}

.textfield:-ms-input-placeholder {
  color: #888;
  opacity: 1;
}

.textfield--white {
  color: #fff;
}

.textfield[disabled], .textfield.disabled {
  color: #000;
  opacity: 0.6;
  border-color: transparent;
  cursor: default;
  cursor: not-allowed;
  z-index: 2;
}

textarea.textfield {
  white-space: normal;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
}

/*

Buttons

*/
.btn {
  position: relative;
  display: inline-block;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  outline: none;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  font-family: Geometria, Arial, sans-serif;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 0;
  border: none;
  padding: 0 30px;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 65px;
  height: 65px;
  min-width: 65px;
  border-radius: 10px;
  color: #000 !important;
  -webkit-appearance: none;
  background: #fff200;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: color 0.2s, background-color 0.2s, -webkit-box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, -webkit-box-shadow 0.2s;
  -o-transition: color 0.2s, background-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
  cursor: pointer;
  z-index: 0;
}

.btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid transparent;
  content: '';
}

.btn::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

.btn:hover {
  text-decoration: none;
  background-color: #f0e300;
  -webkit-box-shadow: 0 3px 0 #ccc200;
          box-shadow: 0 3px 0 #ccc200;
}

.btn:active {
  text-decoration: none;
  background-color: #f0e300;
  -webkit-box-shadow: 0 3px 0 #ccc200;
          box-shadow: 0 3px 0 #ccc200;
}

.btn[disabled], .btn.disabled {
  background: #fff200 !important;
  color: #000 !important;
  cursor: default;
  cursor: not-allowed;
  opacity: 0.5;
}

.btn + .btn {
  margin-left: 16px;
}

.btn > input[type='file'] {
  opacity: 0;
  font-size: 120px;
  position: absolute;
  top: -20px;
  right: -10px;
  vertical-align: top;
  z-index: 3;
  cursor: pointer;
}

.btn--trigger-parent:after {
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  content: '';
}

.btn--fullwidth {
  width: 100%;
  margin-top: 20px;
}

.btn--fullwidth:first-child {
  margin-top: 0;
}

.btn--small {
  font-size: 14px;
  padding: 0 30px;
  line-height: 45px;
  height: 45px;
  min-width: 45px;
  border-radius: 5px;
}

.btn-arrow-left:before {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  top: -2px;
  margin-left: -10px;
  margin-right: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1;
  content: "← ";
}

.btn-arrow-right:after {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  top: -2px;
  margin-left: 5px;
  margin-right: -10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1;
  content: " →";
}

.btn--shadow {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.btn--shadow:hover {
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.btn--shadow.active, .btn--shadow:active {
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

/*

Checkboxes

*/
.checkboxes {
  margin-top: 20px;
  margin-right: -25px;
  padding-top: 3px;
}

.checkboxes:first-child {
  margin-top: 0;
}

.checkboxes:after {
  content: "";
  display: table;
  clear: both;
}

.checkboxes__inner {
  float: left;
  width: 100%;
  margin-bottom: -10px;
  font-size: 12px;
  line-height: 1.41667;
}

.checkbox {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.55;
  min-height: 20px;
  padding-left: 30px;
  margin-bottom: 10px;
  margin-right: 25px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.checkbox:before {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  content: '';
}

.touchevents .checkbox:before {
  display: block;
}

.checkbox__icon {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  background-color: transparent;
  color: currentColor;
  border-radius: 2px;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.checkbox__icon:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid currentColor;
  opacity: 0.45;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.checkbox__icon .icon {
  vertical-align: middle;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.checkbox > input[type='radio'],
.checkbox > input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}

.checkbox:hover .checkbox__icon:before {
  border-color: currentColor;
}

.checkbox.checked .checkbox__icon:before {
  opacity: 1;
  border-color: currentColor;
}

.checkbox.checked .checkbox__icon .icon {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

.checkbox.disabled {
  cursor: default;
  cursor: not-allowed;
  opacity: 0.5;
}

.checkbox.disabled .checkbox__icon {
  opacity: 0.5;
}

.checkbox--radio .checkbox__icon {
  border-radius: 50%;
  color: #fff200;
  background: transparent;
}

.checkbox--radio .checkbox__icon .icon {
  display: none;
}

.checkbox--radio .checkbox__icon:before {
  border-radius: 50%;
  opacity: 1;
}

.checkbox--radio .checkbox__icon:after {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: currentColor;
  border-radius: 50%;
  content: '';
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.checkbox--radio.checked .checkbox__icon {
  background: transparent;
}

.checkbox--radio.checked .checkbox__icon:before {
  background: transparent;
}

.checkbox--radio.checked .checkbox__icon:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.checkbox--block {
  display: block;
}

.checkbox.checked .checkbox__icon:after,
.checkbox-plain-js.checked .checkbox__icon:after {
  visibility: visible;
}

/*

Range Slider

*/
.range-slider-wrapper {
  position: relative;
  margin-top: 20px;
}

.range-slider-wrapper:first-child {
  margin-top: 0;
}

.range-slider {
  margin-top: 20px;
  min-height: 2px;
}

.range-slider:first-child {
  margin-top: 0;
}

.noUi-target {
  position: relative;
  height: 2px;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-left: 10px;
  margin-right: 10px;
  background: transparent;
}

.noUi-target:before {
  position: absolute;
  top: 0;
  right: -10px;
  bottom: 0;
  left: -10px;
  background-color: #e8e8e8;
  content: '';
}

.noUi-connects {
  margin: 0 -10px;
}

.noUi-base:before {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}

.noUi-target .noUi-connect {
  background-color: #fff200;
  border-radius: 0;
}

.noUi-target .noUi-pips-horizontal {
  bottom: 100%;
  top: auto;
  padding: 0;
  height: 2px;
}

.noUi-target .noUi-handle {
  height: 20px;
  width: 20px;
  background: #fff200;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 50%;
  right: -10px;
  top: -9px;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.noUi-target .noUi-handle:before {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  width: auto;
  height: auto;
  background: none;
  content: '';
}

.noUi-target .noUi-handle:hover {
  background-color: #f0e300;
}

.noUi-target .noUi-handle:active {
  background-color: #f0e300;
}

.noUi-target .noUi-handle-upper {
  float: right;
  left: 13px;
}

.noUi-target .noUi-handle:after {
  display: none;
}

.noUi-target .noUi-tooltip {
  border: 0;
  font-weight: 300;
  white-space: nowrap;
  padding: 8px 10px 6px;
  min-width: 20px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 1;
  -webkit-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  top: -36px;
  bottom: auto;
  text-align: center;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  pointer-events: none;
}

.noUi-target .noUi-tooltip:before {
  position: absolute;
  top: 100%;
  left: 50%;
  height: 30px;
  width: 30px;
  margin-left: -16px;
  margin-top: -10px;
  content: '';
}

.noUi-pips {
  color: inherit;
}

.noUi-value {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.55;
  margin-top: 20px;
}

@media (max-width: 479px) {
  .noUi-value {
    margin-top: 10px;
  }
}

.noUi-marker:first-child + .noUi-value {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  margin-left: -10px;
}

.noUi-value:last-child {
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
  margin-left: 10px;
}

/*

Loaders

*/
.loader {
  display: inline-block;
  vertical-align: middle;
  height: 60px;
  width: 60px;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  pointer-events: none;
  text-indent: 0;
}

.btn .loader, .loader-set-absolute > .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
}

.btn--small .loader {
  height: 40px;
  width: 40px;
  margin: -20px 0 0 -20px;
}

.loading > .loader {
  opacity: 1;
}

.loader.active {
  opacity: 1;
}

.loader * {
  fill: currentColor;
}

.btn .loader * {
  fill: currentColor;
}

.loader svg {
  width: 100%;
  height: 100%;
}

.btn.loading {
  pointer-events: none;
}

.btn__loader-inner {
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.loading > .btn__loader-inner {
  opacity: 0;
}

/*

Form's layout

*/
.form {
  position: relative;
  margin-top: 75px;
}

.form:after {
  content: "";
  display: table;
  clear: both;
}

.form:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .form {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .form {
    margin-top: 30px;
  }
}

.form__title {
  font-size: 38px;
  font-weight: 700;
  margin-top: 30px;
}

.form__title:first-child {
  margin-top: 0;
}

@media (max-width: 479px) {
  .form__title {
    font-size: 34px;
    margin-top: 20px;
  }
}

@media (max-width: 374px) {
  .form__title {
    font-size: 30px;
  }
}

.form__subtitle {
  font-size: 20px;
  line-height: 1.35;
  font-weight: 700;
  margin-top: 30px;
}

.form__subtitle:first-child {
  margin-top: 0;
}

@media (max-width: 479px) {
  .form__subtitle {
    font-size: 16px;
    margin-top: 20px;
  }
}

.form__items {
  position: relative;
  margin-top: 20px;
}

.form__items:after {
  content: "";
  display: table;
  clear: both;
}

.form__items:first-child {
  margin-top: 0;
}

.form__item {
  position: relative;
  margin-top: 25px;
}

.form__item:after {
  content: "";
  display: table;
  clear: both;
}

.form__item:first-child {
  margin-top: 0;
}

.form__item-label {
  display: block;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.55;
  margin-top: 5px;
}

.form__item-label:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .form__item-label {
    font-size: 16px;
    line-height: 1.45;
  }
}

.form__item-field {
  position: relative;
  margin-top: 5px;
}

.form__item-field:first-child {
  margin-top: 0;
}

.form__actions {
  position: relative;
  margin-top: 40px;
}

.form__actions:first-child {
  margin-top: 0;
}

.form__error {
  padding: 5px 0 0;
  font-size: 13px;
  color: #f00;
  -webkit-animation: 0.5s ease-out;
          animation: 0.5s ease-out;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation-name: pulsateError;
          animation-name: pulsateError;
}

.form__item-field--error-absolute .form__error {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
}

.form__item-field--error-white .form__error {
  color: #fff;
}

.form__error.active {
  -webkit-animation-name: pulsateError;
          animation-name: pulsateError;
}

.form__tip {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.55;
  margin-top: 30px;
}

.form__tip:first-child {
  margin-top: 0;
}

.form__message {
  display: none;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.55;
  text-align: center;
  background: #62C584;
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.form__message.active {
  display: block;
}

.form.inactive .form__items {
  display: none;
}

@-webkit-keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

.form-required {
  color: #f5203a;
}

.cols-wrapper--form-pager {
  margin-top: 30px;
}

.cols-wrapper--form-pager:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .cols-wrapper--form-pager > .cols {
    display: block;
    margin-bottom: -20px;
  }
}

.cols-wrapper--form-pager > .cols > .col {
  vertical-align: middle;
}

@media (max-width: 767px) {
  .cols-wrapper--form-pager > .cols > .col {
    display: block;
    width: auto;
    margin-bottom: 20px;
    text-align: center;
    clear: both;
  }
  .cols-wrapper--form-pager > .cols > .col:first-child {
    float: left;
    clear: none;
  }
  .cols-wrapper--form-pager > .cols > .col:nth-child(2) {
    float: right;
    clear: none;
  }
}

.form-pager {
  margin-top: 30px;
  font-size: 0;
  line-height: 45px;
  margin-right: -7px;
}

.form-pager:first-child {
  margin-top: 0;
}

.form-pager__text {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.55;
  opacity: 0.3;
  margin-right: 7px;
  min-width: 27px;
  text-align: right;
}

.form-pager__progress {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.55;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-right: 7px;
}

.form-pager__progress circle {
  stroke-dashoffset: 62.8319;
  -webkit-transition: stroke-dashoffset 0.2s linear;
  -o-transition: stroke-dashoffset 0.2s linear;
  transition: stroke-dashoffset 0.2s linear;
  stroke-width: 2px;
}

.form-pager__progress-circle {
  stroke: #000000;
  opacity: 0.3;
}

.form-pager__progress-bar {
  stroke-dashoffset: 31.416 !important;
  stroke: #fff200;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.form-pager__progress.active .form-pager__progress-bar {
  stroke-dashoffset: 0 !important;
}

/*

Popups

 */
.fancybox-thumbs > ul > li:before {
  width: auto;
  height: auto;
  margin: 0;
  background: none;
  border-radius: 0;
}

.fancybox-stage {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
}

.fancybox-container {
  z-index: 9993;
}

.fancybox-slide--html {
  padding: 30px;
}

@media (max-width: 767px) {
  .fancybox-slide--html {
    padding: 0;
  }
}

.fancybox-slide--image {
  padding: 44px 56px 0;
}

@media (max-width: 1023px) {
  .fancybox-slide--image {
    padding-left: 0;
    padding-right: 0;
  }
}

.fancybox-bg {
  background-color: #252525 !important;
}

.fancybox-container--popup {
  opacity: 0;
  -webkit-transition-duration: 0.7s !important;
       -o-transition-duration: 0.7s !important;
          transition-duration: 0.7s !important;
}

.fancybox-container--popup .fancybox-inner {
  opacity: 0;
  -webkit-transform: translateY(150px);
      -ms-transform: translateY(150px);
          transform: translateY(150px);
  -webkit-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
  -webkit-transition-property: all, opacity;
  -o-transition-property: all, opacity;
  transition-property: all, opacity;
  -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
       -o-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
          transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-container--popup .fancybox-bg {
  -webkit-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}

@media (max-width: 767px) {
  .fancybox-container--popup .fancybox-bg {
    background-color: #fff !important;
  }
}

.fancybox-container--popup.fancybox-is-open {
  opacity: 1;
  -webkit-transition-duration: 0.1s !important;
       -o-transition-duration: 0.1s !important;
          transition-duration: 0.1s !important;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.fancybox-container--popup.fancybox-is-open .fancybox-bg {
  opacity: .45;
  -webkit-transition-duration: 0.7s;
       -o-transition-duration: 0.7s;
          transition-duration: 0.7s;
}

.fancybox-container--popup.fancybox-is-open .fancybox-inner {
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-delay: 0.2s;
       -o-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.fancybox-container--popup.fancybox-is-close {
  opacity: 0;
  -webkit-transition-duration: 0.1s !important;
       -o-transition-duration: 0.1s !important;
          transition-duration: 0.1s !important;
  -webkit-transition-delay: 0.6s;
       -o-transition-delay: 0.6s;
          transition-delay: 0.6s;
}

.fancybox-container--popup.fancybox-is-close .fancybox-bg {
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-delay: 0.2s;
       -o-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.fancybox-container--popup.fancybox-is-close .fancybox-inner {
  -webkit-transform: translateY(-150px);
      -ms-transform: translateY(-150px);
          transform: translateY(-150px);
  -webkit-transition-duration: 0.5s, 0.5s;
       -o-transition-duration: 0.5s, 0.5s;
          transition-duration: 0.5s, 0.5s;
  -webkit-transition-delay: 0s, 0.2s;
       -o-transition-delay: 0s, 0.2s;
          transition-delay: 0s, 0.2s;
}

.fancybox-container--popup .fancybox-slide {
  overflow-x: hidden;
  font-size: 0;
  white-space: nowrap;
}

.fancybox-container--popup .fancybox-slide .fancybox-content {
  margin-bottom: 0;
  overflow: visible;
}

@media (max-width: 413px) {
  .fancybox-container--popup .fancybox-slide .fancybox-content {
    width: 100%;
  }
}

.fancybox-container--popup .fancybox-slide > * {
  white-space: normal;
  font-size: 1rem;
  padding: 0;
  background-color: transparent;
}

.fancybox-container--popup .fancybox-slide > div {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.fancybox-container--popup .fancybox-slide > .fancybox-loading {
  width: 48px;
  height: 48px;
  margin-top: -24px;
}

.fancybox-container--popup .fancybox-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fancybox-close {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 32px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #1e1e1e;
  color: #fff;
  z-index: 1;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

@media (max-width: 1023px) {
  .fancybox-close {
    height: 64px;
    width: 64px;
    line-height: 64px;
  }
}

.fancybox-close:hover {
  color: #fff200;
}

.fancybox-close:hover .icon {
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.fancybox-close .icon {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.fancybox-container .fancybox-close-hide .fancybox-close {
  display: none;
}

.fancybox-content {
  margin: 0 0 44px 0;
  padding: 44px;
}

.fancybox-infobar {
  height: 44px;
  line-height: 44px;
  min-width: 44px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}

.fancybox-button {
  width: 44px;
  height: 44px;
  padding: 10px;
}

.fancybox-navigation .fancybox-button {
  height: 100px;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
}

.fancybox-thumbs {
  background-color: #000;
}

.fancybox-thumbs > ul > li {
  border-color: transparent;
}

.fancybox-thumbs > ul > li:before {
  border-color: #fff200;
  border-width: 2px;
}

.fancybox-show-thumbs .fancybox-button--thumbs {
  background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 767px) {
  .fancybox-slide > div {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.fancybox-slide--video > div {
  padding: 0;
}

.fancybox-slide--iframe, .fancybox-slide--video {
  padding-top: 96px;
  padding-bottom: 24px;
}

@media (max-width: 1023px) {
  .fancybox-slide--iframe, .fancybox-slide--video {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) and (min-aspect-ratio: 320 / 320), (max-height: 413px) {
  .fancybox-slide--iframe, .fancybox-slide--video {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--video .fancybox-content {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .fancybox-slide--iframe .fancybox-content, .fancybox-slide--video .fancybox-content {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.fancybox-container--iframe .fancybox-close,
.fancybox-container--video .fancybox-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767px) and (min-aspect-ratio: 320 / 320), (max-height: 413px) {
  .fancybox-container--iframe .fancybox-close,
  .fancybox-container--video .fancybox-close {
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    margin: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-radius: 0;
  }
}

.fancybox-container--iframe .fancybox-toolbar,
.fancybox-container--video .fancybox-toolbar {
  display: none;
}

.popover {
  position: fixed;
  z-index: 100;
  width: 370px;
  padding: 16px;
  background-color: #fff;
  -webkit-box-shadow: 0 15px 30px rgba(43, 43, 43, 0.1);
          box-shadow: 0 15px 30px rgba(43, 43, 43, 0.1);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transform: translateX(calc(100% + 40px));
      -ms-transform: translateX(calc(100% + 40px));
          transform: translateX(calc(100% + 40px));
  -webkit-transition: all 0.5s, opacity 0.1s 0.4s, visibility 0s 0.5s, margin 0s;
  -o-transition: all 0.5s, opacity 0.1s 0.4s, visibility 0s 0.5s, margin 0s;
  transition: all 0.5s, opacity 0.1s 0.4s, visibility 0s 0.5s, margin 0s;
}

@media (max-width: 1023px) {
  .popover {
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px 24px;
    -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
            transform: translateY(100%);
  }
}

.popover .action {
  margin-top: 15px;
}

.popover .action:first-child {
  margin-top: 0;
}

.popover.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.popover--rb {
  right: 40px;
  bottom: 40px;
}

@media (max-height: 1259px) {
  .popover--rb {
    right: 24px;
    bottom: 24px;
  }
}

@media (max-width: 1023px) {
  .popover--rb {
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

/*

Panels

 */
.panel {
  position: relative;
  width: 840px;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  padding: 0 !important;
  margin-top: 66px;
  -webkit-box-shadow: 0 15px 30px rgba(43, 43, 43, 0.1);
          box-shadow: 0 15px 30px rgba(43, 43, 43, 0.1);
}

@media (max-width: 1259px) {
  .panel {
    width: 800px;
    max-width: 800px;
  }
}

@media (max-width: 1023px) {
  .panel {
    width: 460px;
    max-width: 460px;
  }
}

@media (max-width: 767px) {
  .panel {
    width: 388px;
    max-width: 388px;
  }
}

@media (max-width: 413px) {
  .panel {
    width: 100%;
    max-width: none;
  }
}

.panel__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100%;
  background: #fff;
  color: #000 !important;
  padding: 70px;
}

@media (max-width: 1259px) {
  .panel__content {
    padding: 48px;
  }
}

@media (max-width: 767px) {
  .panel__content {
    padding: 32px 24px;
  }
}

@media (max-width: 413px) {
  .panel__content {
    padding-left: 16px;
    padding-right: 16px;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  background: #fff;
  height: 100%;
}

body {
  position: relative;
  font-family: Geometria, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 320px;
  min-height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

html, body {
  font-size: 20px;
}

@media (max-width: 767px) {
  html, body {
    font-size: 16px;
  }
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.no-animation-only-this {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-animation {
  -webkit-animation-delay: 0s !important;
          animation-delay: 0s !important;
  -webkit-animation-duration: 0s !important;
          animation-duration: 0s !important;
}

.no-animation *:before, .no-animation *:after {
  -webkit-animation-delay: 0s !important;
          animation-delay: 0s !important;
  -webkit-animation-duration: 0s !important;
          animation-duration: 0s !important;
}

.no-transition-only-this {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-transition {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-transition *, .no-transition *:before, .no-transition *:after {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

::-moz-selection {
  background: #eee;
  color: #000;
  text-shadow: none;
}

::selection {
  background: #eee;
  color: #000;
  text-shadow: none;
}

/*
*
* Skeleton elements
*
*/
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.wrap {
  float: left;
  width: 100%;
  min-height: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1220px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

@media (max-width: 1259px) {
  .container {
    max-width: 1100px;
  }
}

@media (max-width: 1023px) {
  .container {
    max-width: 800px;
  }
}

@media (max-width: 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 374px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.only-for-notouch,
.only-for-touch,
.only-for-notouch-inline,
.only-for-touch-inline,
.only-for-notouch-inline-block,
.only-for-touch-inline-block {
  display: none !important;
}

.no-touchevents .only-for-notouch {
  display: block !important;
}

.no-touchevents .only-for-notouch-inline {
  display: inline !important;
}

.no-touchevents .only-for-notouch-inline-block {
  display: inline-block !important;
}

.touchevents .only-for-touch {
  display: block !important;
}

.touchevents .only-for-touch-inline {
  display: inline !important;
}

.touchevents .only-for-touch-inline-block {
  display: inline-block !important;
}

.screen-xl-show,
.screen-xl-show-inline-block,
.screen-xl-show-inline,
.screen-xl-show-table-row,
.screen-xl-show-table-cell {
  display: none !important;
}

.tablet-show,
.tablet-show-inline-block,
.tablet-show-inline,
.tablet-show-table-row,
.tablet-show-table-cell {
  display: none !important;
}

.tablet-small-show,
.tablet-small-show-inline-block,
.tablet-small-show-inline,
.tablet-small-show-table-row,
.tablet-small-show-table-cell {
  display: none !important;
}

.mobile-show,
.mobile-show-inline-block,
.mobile-show-inline,
.mobile-show-table-cell {
  display: none !important;
}

.mobile-small-show,
.mobile-small-show-inline-block,
.mobile-small-show-inline,
.mobile-small-show-table-cell {
  display: none !important;
}

.mobile-xsmall-show,
.mobile-xsmall-show-inline-block,
.mobile-xsmall-show-inline,
.mobile-xsmall-show-table-cell {
  display: none !important;
}

.mobile-xxsmall-show,
.mobile-xxsmall-show-inline-block,
.mobile-xxsmall-show-inline,
.mobile-xxsmall-show-table-cell {
  display: none !important;
}

.mobile-xxxsmall-show,
.mobile-xxxsmall-show-inline-block,
.mobile-xxxsmall-show-inline,
.mobile-xxxsmall-show-table-cell {
  display: none !important;
}

.initial-hide {
  display: none !important;
}

.initial-show {
  display: block !important;
}

.initial-show-inline-block {
  display: inline-block !important;
}

.initial-show-inline {
  display: inline !important;
}

.initial-show-table-row {
  display: table-row !important;
}

.initial-show-table-cell {
  display: table-cell !important;
}

@media (max-width: 1900px) {
  .screen-xl-hide {
    display: none !important;
  }
  .screen-xl-show {
    display: block !important;
  }
  .screen-xl-show-inline-block {
    display: inline-block !important;
  }
  .screen-xl-show-inline {
    display: inline !important;
  }
  .screen-xl-show-table-row {
    display: table-row !important;
  }
  .screen-xl-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 1259px) {
  .tablet-hide {
    display: none !important;
  }
  .tablet-show {
    display: block !important;
  }
  .tablet-show-inline-block {
    display: inline-block !important;
  }
  .tablet-show-inline {
    display: inline !important;
  }
  .tablet-show-table-row {
    display: table-row !important;
  }
  .tablet-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 1023px) {
  .tablet-small-hide {
    display: none !important;
  }
  .tablet-small-show {
    display: block !important;
  }
  .tablet-small-show-inline-block {
    display: inline-block !important;
  }
  .tablet-small-show-inline {
    display: inline !important;
  }
  .tablet-small-show-table-row {
    display: table-row !important;
  }
  .tablet-small-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
  .mobile-show-inline-block {
    display: inline-block !important;
  }
  .mobile-show-inline {
    display: inline !important;
  }
  .mobile-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 479px) {
  .mobile-small-hide {
    display: none !important;
  }
  .mobile-small-show {
    display: block !important;
  }
  .mobile-small-show-inline-block {
    display: inline-block !important;
  }
  .mobile-small-show-inline {
    display: inline !important;
  }
  .mobile-small-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 413px) {
  .mobile-xsmall-hide {
    display: none !important;
  }
  .mobile-xsmall-show {
    display: block !important;
  }
  .mobile-xsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xsmall-show-inline {
    display: inline !important;
  }
  .mobile-xsmall-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 374px) {
  .mobile-xxsmall-hide {
    display: none !important;
  }
  .mobile-xxsmall-show {
    display: block !important;
  }
  .mobile-xxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxsmall-show-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 359px) {
  .mobile-xxxsmall-hide {
    display: none !important;
  }
  .mobile-xxxsmall-show {
    display: block !important;
  }
  .mobile-xxxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxxsmall-show-table-cell {
    display: table-cell !important;
  }
}

/*
Лейаут шапки
*/
.header {
  background-color: #fff;
}

.header--grey {
  background-color: #f0f0f0;
}

@media (max-width: 1259px) {
  .header .col--logo {
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .header .col--logo {
    float: left;
  }
}

.header .col--title {
  font-size: 28px;
  font-weight: 800;
  text-align: center;
}

@media (max-width: 1259px) {
  .header .col--title {
    position: absolute;
    right: 0;
    bottom: 15px;
    margin-left: auto;
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
}

@media (max-width: 767px) {
  .header .col--title {
    bottom: 15px;
    font-size: 15px;
  }
}

.header .col--text {
  font-size: 18px;
  font-weight: 500;
  text-align: right;
}

@media (max-width: 1259px) {
  .header .col--text {
    margin-left: auto;
    margin-bottom: 45px;
  }
}

@media (max-width: 767px) {
  .header .col--text {
    float: right;
    margin-bottom: 30px;
    font-size: 14px;
  }
}

.header .col--action {
  width: 1%;
}

@media (max-width: 1259px) {
  .header .col--action {
    width: auto;
    margin-bottom: 45px;
  }
}

@media (max-width: 767px) {
  .header .col--action {
    clear: right;
    float: right;
  }
}

.cols-wrapper--header > .cols {
  position: relative;
}

@media (max-width: 1259px) {
  .cols-wrapper--header > .cols {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 170px;
  }
}

@media (max-width: 767px) {
  .cols-wrapper--header > .cols {
    height: 90px;
  }
}

.cols-wrapper--header > .cols > .col {
  vertical-align: middle;
  height: 170px;
}

@media (max-width: 1259px) {
  .cols-wrapper--header > .cols > .col {
    display: block;
    height: auto;
  }
}

/*
Лого
*/
.logo {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 150px;
  line-height: 1;
  color: inherit;
  text-decoration: none !important;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 767px) {
  .logo {
    width: 80px;
  }
}

.logo:before {
  display: block;
  padding-bottom: 94.93671%;
  content: '';
}

.logo img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

/*
Лейаут футера
*/
.footer {
  background-color: #f0f0f0;
  margin-top: auto;
}

@media (max-width: 767px) {
  .footer {
    padding: 50px 0;
  }
}

.footer .col--logo {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
}

.footer .col--text {
  font-weight: 500;
  text-align: right;
}

.footer .col--action {
  width: 1%;
}

@media (max-width: 767px) {
  .footer .logo {
    width: 130px;
  }
}

@media (max-width: 767px) {
  .cols-wrapper--footer > .cols {
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: -30px;
  }
}

.cols-wrapper--footer > .cols > .col {
  vertical-align: middle;
  height: 190px;
}

@media (max-width: 767px) {
  .cols-wrapper--footer > .cols > .col {
    display: block;
    height: auto;
    text-align: center;
    width: auto;
    margin-bottom: 30px;
  }
  .cols-wrapper--footer > .cols > .col--text {
    margin-bottom: 15px;
  }
}

.b-lazy {
  min-width: 1px;
  min-height: 1px;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.b-loaded {
  opacity: 1;
}

.img-to-bg-lazy {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.img-to-bg-lazy-loaded {
  opacity: 1;
}

/*
Content Section
*/
.section {
  position: relative;
  z-index: 1;
  padding: 60px 0 90px;
}

@media (max-width: 1023px) {
  .section {
    padding-bottom: 60px;
  }
}

.section--bring-to-front {
  z-index: 2;
}

.section--bring-to-back {
  z-index: 0;
}

.section--fullheight {
  -webkit-box-flex: 1;
      -ms-flex: auto 1 1;
          flex: auto 1 1;
}

.section--middle {
  margin-top: auto;
  margin-bottom: auto;
}

.section__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center center no-repeat;
  background-size: cover;
  z-index: -1;
  overflow: hidden;
}

.section__bg--z-index-auto {
  z-index: auto;
}

.section__bg--contain {
  background-size: contain;
}

.section__bg-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center center no-repeat;
  background-size: cover;
}

.section__bg img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -100;
}

.section__z-index-1 {
  position: relative;
  z-index: 1;
}

.section--hero {
  background-color: #00cff8;
  padding: 0;
  overflow: hidden;
  z-index: auto;
}

@media (max-width: 767px) {
  .section--hero {
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: visible;
  }
}

@media (max-width: 479px) {
  .section--hero {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .section--hero .col--image {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }
}

.section--hero .col--form {
  width: 1%;
  padding-top: 50px;
  padding-bottom: 50px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .section--hero .col--form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    -webkit-transform: none !important;
        -ms-transform: none !important;
            transform: none !important;
    -webkit-transition-delay: 0s !important;
         -o-transition-delay: 0s !important;
            transition-delay: 0s !important;
  }
}

@media (max-width: 767px) {
  .cols-wrapper--hero > .cols {
    display: block;
  }
}

@media (max-width: 767px) {
  .cols-wrapper--hero > .cols > .col {
    display: block;
  }
}

.hero-image {
  position: relative;
  margin: -10% -150px -10% -350px;
  background: center bottom no-repeat;
  background-size: contain;
  overflow: hidden;
}

@media (max-width: 1259px) {
  .hero-image {
    margin-right: -200px;
    margin-left: -260px;
  }
}

@media (max-width: 1023px) {
  .hero-image {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .hero-image {
    margin: -5% 0 -20% -5%;
    -webkit-transition-delay: 0.2s !important;
         -o-transition-delay: 0.2s !important;
            transition-delay: 0.2s !important;
  }
}

.hero-image:before {
  display: block;
  padding-bottom: 76%;
  content: '';
}

.hero-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -100;
}

.form--hero {
  display: inline-block;
  vertical-align: top;
  width: 420px;
  margin-left: auto;
  border: 6px solid #000;
  border-radius: 10px;
  padding: 45px 50px;
  background-color: #fff;
  text-align: center;
}

@media (max-width: 767px) {
  .form--hero {
    margin-right: auto;
    max-width: 420px;
    width: 100%;
  }
}

@media (max-width: 479px) {
  .form--hero {
    position: relative;
    border-radius: 0;
    max-width: none;
    width: auto;
    display: block;
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px 20px 40px;
    border: none;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    z-index: 2;
  }
}

.form--hero .form__actions {
  margin-top: 40px;
}

.form--hero .form__actions:first-child {
  margin-top: 0;
}

@media (max-width: 479px) {
  .form--hero .form__actions {
    margin-top: 30px;
  }
}

.form--hero .range-slider-wrapper {
  max-width: 310px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
  min-height: 55px;
}

@media (max-width: 479px) {
  .form--hero .range-slider-wrapper {
    min-height: 40px;
    padding-top: 20px;
  }
}

.range-slider-wrapper {
  padding-top: 45px;
  min-height: 80px;
}

@media (max-width: 479px) {
  .range-slider-wrapper {
    padding-top: 45px;
    min-height: 80px;
  }
}

.range-slider-wrapper .noUi-marker-horizontal.noUi-marker {
  display: none;
}

.atgbs {
  margin: 0 -20px;
  margin-top: 50px;
}

.atgbs:first-child {
  margin-top: 0;
}

.atgbs:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .atgbs {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.atgbs__inner {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-size: 0;
  margin: -20px 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}

.atgb {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  padding: 20px;
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 1023px) {
  .atgb {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .atgb {
    width: 100%;
    max-width: 320px;
    font-size: 18px;
  }
}

.atgb__image {
  line-height: 90px;
}

.atgb__image img {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: auto;
  max-width: 90px;
  max-height: 90px;
}

.atgb__title {
  font-weight: 500;
  margin-top: 25px;
}

.atgb__title:first-child {
  margin-top: 0;
}

.stgbs {
  margin: 0 -20px;
  margin-top: 125px;
  counter-reset: stgb;
}

.stgbs:first-child {
  margin-top: 0;
}

.stgbs:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1259px) {
  .stgbs {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 80px;
  }
}

.stgbs__inner {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0;
  margin: -30px 0;
  text-align: center;
}

.stgb {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 30px 20px;
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 1259px) {
  .stgb {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1023px) {
  .stgb {
    width: 50%;
    max-width: 370px;
  }
}

@media (max-width: 767px) {
  .stgb {
    width: 100%;
  }
}

.stgb__inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  padding: 75px 30px 40px;
  border: 5px solid #f0f0f0;
  border-radius: 10px;
  background: #fff;
}

@media (max-width: 1259px) {
  .stgb__inner {
    padding: 65px 15px 30px;
  }
}

@media (max-width: 1023px) {
  .stgb__inner {
    padding: 65px 30px 30px;
  }
}

.stgb__inner:before {
  position: absolute;
  top: 0;
  left: 50%;
  height: 80px;
  width: 80px;
  margin: -40px 0 0 -40px;
  font-size: 30px;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff200;
  border: 5px solid #000;
  border-radius: 50%;
  content: counter(stgb);
  counter-increment: stgb;
}

.stgb__title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.35;
  margin-top: 30px;
}

.stgb__title:first-child {
  margin-top: 0;
}

.stgb__summary {
  font-size: 18px;
  margin-top: 15px;
}

.stgb__summary:first-child {
  margin-top: 0;
}

.stgb__image {
  line-height: 70px;
  padding-top: 20px;
  margin-top: auto;
}

.stgb__image img {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: auto;
  max-width: 70px;
  max-height: 70px;
}

.bblock {
  margin-top: 65px;
  padding: 45px 50px;
  border: 5px solid #f0f0f0;
  border-radius: 10px;
}

.bblock:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .bblock {
    padding: 25px 30px;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .bblock {
    margin-top: 30px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

.ptgbs {
  margin: 0 -20px;
  margin-top: 50px;
}

.ptgbs:first-child {
  margin-top: 0;
}

.ptgbs:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .ptgbs {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media (max-width: 767px) {
  .ptgbs {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.ptgbs__inner {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0;
  margin: -10px 0;
}

@media (max-width: 1023px) {
  .ptgbs__inner {
    min-height: 255px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.ptgb {
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 1023px) {
  .ptgb {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .ptgb {
    padding: 10px;
  }
}

.ptgb__image {
  line-height: 80px;
  min-height: 80px;
  min-width: 80px;
}

@media (max-width: 767px) {
  .ptgb__image {
    line-height: 50px;
    min-height: 50px;
    min-width: 50px;
  }
}

.ptgb__image img {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .ptgb__image img {
    width: 100%;
    height: auto;
    max-width: 100px;
    max-height: 50px;
  }
}

.cols-wrapper--about {
  margin-top: 125px;
}

.cols-wrapper--about:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .cols-wrapper--about {
    margin-top: 80px;
  }
}

.cols-wrapper--about > .cols {
  display: block;
}

@media (max-width: 1023px) {
  .cols-wrapper--about > .cols > .col {
    display: block;
    margin-top: 25px;
    width: auto;
  }
  .cols-wrapper--about > .cols > .col:first-child {
    margin-top: 0;
  }
}

.sblock {
  margin-top: 150px;
  padding: 60px;
  background: #f0f0f0;
  font-size: 14px;
  font-weight: 300;
}

.sblock:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .sblock {
    padding: 25px 30px;
    margin-top: 120px;
  }
}

@media (max-width: 767px) {
  .sblock {
    margin-left: -20px;
    margin-right: -20px;
    padding: 25px 20px;
    margin-top: 80px;
    font-size: 13px;
  }
}

@media (max-width: 374px) {
  .sblock {
    margin-left: -15px;
    margin-right: -15px;
    padding: 20px 15px;
  }
}

.section--default {
  padding-top: 105px;
  padding-bottom: 105px;
}

@media (max-width: 1350px) {
  .section--default {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 1023px) {
  .section--default {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .section--default {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.form--quote {
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
}

.form--quote .form-page {
  min-height: 415px;
}

@media (max-width: 767px) {
  .form--quote .form-page {
    min-height: 375px;
  }
}

/*

Common property classes

 */
.relative {
  position: relative;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}

.reset-margin {
  margin: 0;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .margin-auto-mobile {
    margin-left: auto;
    margin-right: auto;
  }
}

.hidden-absolute {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

@media (max-width: 767px) {
  .text-align-left-on-mobile {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .text-align-right-on-mobile {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .text-align-center-on-mobile {
    text-align: center;
  }
}

.text-ellipsis {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
}

.vmiddle {
  vertical-align: middle;
}

.vbottom {
  vertical-align: bottom;
}

.vbaseline {
  vertical-align: baseline;
}

.clear {
  clear: both;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.nowrap {
  white-space: nowrap;
}

.no-text-transform {
  text-transform: none;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.nounderline {
  text-decoration: none;
}

.nounderline-important {
  text-decoration: none !important;
}

.underline-hover:hover {
  text-decoration: underline;
}

.underline-hover:active {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.color-white {
  color: #fff;
}

.color-inherit {
  color: inherit;
}

.color-inherit-important {
  color: inherit !important;
}

.color-default {
  color: #000;
}

.color-muted {
  color: #828282;
}

.color-muted-more {
  color: #bdbdbd;
}

.color-active {
  color: #fff200;
}

.font-0 {
  font-size: 0;
}

.font-small {
  font-size: 12px;
}

.font-smaller {
  font-size: 14px;
}

.font-normal {
  font-size: 16px;
}

.font-larger {
  font-size: 18px;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.text-medium {
  font-size: 16px;
  line-height: 1.5;
}

.text-small {
  font-size: 14px;
  line-height: 1.57143;
}

@media (max-width: 1259px) {
  .text-small {
    font-size: 13px;
    line-height: 1.53846;
  }
}

.mw340 {
  max-width: 340px;
}

.mw360 {
  max-width: 360px;
}

.mw410 {
  max-width: 410px;
}

.mw560 {
  max-width: 560px;
}

.mw640 {
  max-width: 640px;
}

.mw720 {
  max-width: 720px;
}

.mw840 {
  max-width: 840px;
}

.mw870 {
  max-width: 870px;
}

/*# sourceMappingURL=style.css.map */
